<!-- 邀请入职 -->
<template>
  <div class="intive-to-join-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '**',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <!-- 入职邀请验证 -->
    <div v-if="step === 0" class="intive-to-join">
      <div class="intive-content">
        <van-cell class="title-box">
          <p class="title">入职邀请验证</p>
        </van-cell>
        <van-form @failed="onFailed" ref="FormPhone">
          <van-cell>
            <template #title>
              <p class="title">
                <i class="red">* </i>
                手机号
              </p>
            </template>
            <van-field
              v-model.trim="tel"
              label-align="top"
              type="number"
              name="tel"
              :rules="[
                { required: true, message: '请输入手机号' },
                { validator: validatorPhone, message: '手机号格式有误' },
              ]"
            />
          </van-cell>
        </van-form>
      </div>
      <div class="footer-buttons">
        <van-button class="pass" :loading="loading" @click="validNext"
          >下一步</van-button
        >
      </div>
    </div>
    <!-- 入职信息填写 -->
    <div v-if="step === 1" class="intive-to-join">
      <div class="intive-content write">
        <van-form
          @failed="onFailed"
          class="form-staff-info"
          ref="FormStaffInfo"
        >
          <!-- 基本信息 -->
          <div class="card-box">
            <van-cell class="title-box">
              <p class="title">员工信息登记</p>
            </van-cell>
            <div class="form-title">基本信息</div>
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">* </i>
                  01 姓名
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.userName"
                name="userName"
                maxlength="20"
                show-word-limit
                :rules="[{ required: true, message: '请输入姓名' }]"
              />
            </van-cell>
            <!-- 性别 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">* </i>
                  02 性别
                </p>
              </template>

              <van-field
                name="gender"
                :rules="[{ required: true, message: '请选择性别' }]"
              >
                <template #input>
                  <van-radio-group
                    v-model="ruleForm.gender"
                    direction="vertical"
                  >
                    <van-radio :name="1">男</van-radio>
                    <van-divider />
                    <van-radio :name="2">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </van-cell>

            <!-- 民族 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp; </i>
                  03 民族
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.nationality"
                name="nationality"
                :rules="[{ required: true, message: '请输入民族' }]"
              />
            </van-cell>
            <!-- 身份证 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">*</i>
                  04 身份证
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.idCard"
                name="idCard"
                maxlength="18"
                show-word-limit
                :rules="[{ required: true, message: '请输入身份证' }]"
              />
            </van-cell>
            <!-- 出生日期 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">* </i>
                  05 出生日期
                </p>
              </template>
              <van-field
                v-model="ruleForm.birthdayDay"
                readonly
                clickable
                name="birthdayDay"
                @click="showBirthday = true"
                :rules="[{ required: true, message: '请选择出生日期' }]"
              />
            </van-cell>
            <van-popup
              v-model="showBirthday"
              position="bottom"
              :style="{ height: '30%' }"
            >
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择出生日期"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onTimeConfirm"
                @cancel="showBirthday = false"
              />
            </van-popup>
            <!-- 联系电话 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">*</i>
                  06 联系电话
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.phoneNumber"
                name="phoneNumber"
                show-word-limit
                maxlength="11"
                :rules="[
                  { required: true, message: '请输入联系电话' },
                  {
                    validator: validtorTel,
                    message: '联系电话格式不正确',
                  },
                ]"
              />
            </van-cell>
            <!-- 政治面貌 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp; </i>
                  07 政治面貌
                </p>
              </template>

              <van-field
                name="politicsStatus"
                :rules="[{ required: true, message: '请选择政治面貌' }]"
              >
                <template #input>
                  <van-radio-group
                    v-model="ruleForm.politicsStatus"
                    direction="vertical"
                  >
                    <van-radio :name="1">群众</van-radio>
                    <van-divider />
                    <van-radio :name="2">中共党员</van-radio>
                    <van-divider />
                    <van-radio :name="6">中共预备党员</van-radio>
                    <van-divider />
                    <van-radio :name="3">共青团员</van-radio>
                    <van-divider />
                    <van-radio :name="4">其他党派</van-radio>
                    <van-divider />
                    <van-radio :name="5">无党派人士</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </van-cell>

            <!-- 入党时间 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp; </i>
                  08 入党时间
                </p>
              </template>
              <van-field
                v-model="ruleForm.communistTime"
                readonly
                clickable
                name="communistTime"
                @click="showCommunistTime = true"
                :rules="[{ required: true, message: '选择入党时间' }]"
              />
            </van-cell>
            <van-popup
              v-model="showCommunistTime"
              position="bottom"
              :style="{ height: '30%' }"
            >
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择入党时间"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onCommunistTime"
                @cancel="showCommunistTime = false"
              />
            </van-popup>
            <!-- 邮箱 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">*</i>
                  09 邮箱
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.emailAddress"
                name="emailAddress"
                show-word-limit
                maxlength="32"
                :rules="[
                  { required: true, message: '请输入正确邮箱地址' },
                  {
                    validator: validtorEmail,
                    message: '请输入正确邮箱地址',
                  },
                ]"
              />
            </van-cell>
            <!-- 籍贯 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  10 籍贯（省份、城市）
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.nativePlace"
                name="nativePlace"
                maxlength="100"
                type="textarea"
                :rows="5"
                show-word-limit
                :rules="[{ required: true, message: '请输入籍贯' }]"
              />
            </van-cell>
            <!-- 现居地 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  11 现居地（城市、区/县、街道）
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.nowAddress"
                name="nowAddress"
                maxlength="200"
                type="textarea"
                :rows="8"
                show-word-limit
                :rules="[{ required: true, message: '请输入现居地' }]"
              />
            </van-cell>
          </div>
          <!-- 全日制教育 -->
          <div class="card-box">
            <div class="form-title">全日制教育</div>
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">* </i>
                  12 学历
                </p>
              </template>

              <van-field
                name="educationBack"
                :rules="[{ required: true, message: '请选择学历' }]"
              >
                <template #input>
                  <van-radio-group
                    v-model="ruleForm.educationBack"
                    direction="vertical"
                  >
                    <van-radio :name="0">初中及以下</van-radio>
                    <van-divider />
                    <van-radio :name="1">高中</van-radio>
                    <van-divider />
                    <van-radio :name="2">中专/中技</van-radio>
                    <van-divider />
                    <van-radio :name="3">大学大专</van-radio>
                    <van-divider />
                    <van-radio :name="4">大学本科</van-radio>
                    <van-divider />
                    <van-radio :name="5">硕士研究生</van-radio>
                    <van-divider />
                    <van-radio :name="6">博士研究生</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </van-cell>

            <!-- 毕业院校 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">*</i>
                  13 毕业院校
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.collegeName"
                name="collegeName"
                show-word-limit
                maxlength="50"
                type="textarea"
                :rows="3"
                :rules="[{ required: true, message: '请输入毕业院校' }]"
              />
            </van-cell>
            <!-- 院系 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  14 院系
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.faculty"
                name="faculty"
                show-word-limit
                maxlength="50"
                type="textarea"
                :rows="3"
                :rules="[{ required: true, message: '请输入院系' }]"
              />
            </van-cell>
            <!-- 专业 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  15 专业
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.major"
                name="major"
                show-word-limit
                maxlength="50"
                type="textarea"
                :rows="3"
                :rules="[{ required: true, message: '请输入专业' }]"
              />
            </van-cell>
          </div>
          <!-- 入职信息 -->
          <div class="card-box">
            <div class="form-title">入职信息</div>
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">* </i>
                  16 入职日期
                </p>
              </template>
              <van-field
                v-model="ruleForm.entryDate"
                readonly
                clickable
                name="entryDate"
                @click="showEntryDate = true"
                :rules="[{ required: true, message: '选择入职日期' }]"
              />
            </van-cell>
            <van-popup
              v-model="showEntryDate"
              position="bottom"
              :style="{ height: '30%' }"
            >
              <van-datetime-picker
                v-model="currentDate"
                type="date"
                title="选择入职日期"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onEntryDate"
                @cancel="showEntryDate = false"
              />
            </van-popup>
            <!-- 职务 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">*</i>
                  16 职务
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.staffDuty"
                name="staffDuty"
                maxlength="20"
                show-word-limit
                :rules="[{ required: true, message: '请输入职务' }]"
              />
            </van-cell>
            <!-- 工作年限 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">*</i>
                  17 工作年限
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.workYear"
                name="workYear"
                maxlength="4"
                show-word-limit
                type="number"
                :rules="[
                  { required: true, message: '请输入工作年限' },
                  {
                    validator: validtorWorkYear,
                    message: '工作年限格式不正确',
                  },
                ]"
              />
            </van-cell>
            <!-- 职务 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  18 职称证书
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.certificateName"
                name="certificateName"
                maxlength="20"
                show-word-limit
                :rules="[{ required: true, message: '请输入职称证书' }]"
              />
            </van-cell>
            <!-- 紧急联系人 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  19 您的紧急联系人
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.emergencyConcat"
                name="emergencyConcat"
                maxlength="20"
                show-word-limit
                :rules="[{ required: true, message: '请输入紧急联系人' }]"
              />
            </van-cell>
            <!-- 	紧急联系电话 -->
            <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  20 紧急联系电话
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.emergencyPhone"
                name="emergencyConcat"
                maxlength="20"
                type="number"
                show-word-limit
                :rules="[
                  {
                    validator: validtorTel,
                    message: '紧急联系电话格式不正确',
                  },
                ]"
              />
              <!-- { required: true, message: '请输入紧急联系电话' }, -->
            </van-cell>
            <!-- 	入职部门 -->
            <!-- <van-cell class="cell">
              <template #title>
                <p class="title">
                  <i class="red">&nbsp;</i>
                  21 入职部门
                </p>
              </template>
              <van-field
                v-model.trim="ruleForm.emergencyPhone"
                name="emergencyConcat"
                maxlength="20"
                type="number"
                show-word-limit
              />
            </van-cell> -->
          </div>
        </van-form>
      </div>
      <div class="footer-buttons">
        <van-button class="pass" :loading="loading" @click="validKeep"
          >提交信息</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { validEntryInfo, saveStaffInfo } from "@/api/common/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
export default {
  data() {
    return {
      tel: "",
      loading: false,
      step: 0,
      ruleForm: {
        birthdayDay: "",
        certificateName: "",
        communistTime: "",
        educationBack: null,
        collegeName: "",
        emailAddress: "",
        emergencyConcat: "",
        emergencyPhone: "",
        entryDate: "",
        gender: null,
        idCard: "",
        jobType: null,
        nationality: "",
        nativePlace: "",
        nowAddress: "",
        phoneNumber: "",
        politicsStatus: null,
        staffDuty: "",
        userName: "",
        workYear: "",
      },

      showBirthday: false,
      minDate: new Date(1940, 0, 1),
      maxDate: new Date(2040, 10, 1),
      currentDate: new Date(),
      showCommunistTime: false,
      showEntryDate: false,
      headAccesToken: "",
      satv: "",
    };
  },

  components: {TopWrapper },

  computed: {
    assetToken() {
      let url = window.location.href;
      return decodeURIComponent(url.split("?")[1]);
    },
  },

  mounted() {},

  methods: {
    validtorTel(val) {
      if (!val) return true;
      return /^(?:(?:\+|00)86)?1\d{10}$/.test(val);
    },
    validatorPhone(val) {
      return /^(?:(?:\+|00)86)?1\d{10}$/.test(val);
    },
    onFailed(errorInfo) {},
    // 下一步
    validNext() {
      this.$refs.FormPhone.validate("tel").then(() => {
        this.asyncValidEntryPhone();
      });
    },
    // 验证入职信息
    asyncValidEntryPhone() {
      validEntryInfo({
        phone: this.tel,
        evi: this.assetToken,
      }).then((res) => {
        if (res) {
          let { code, data } = res;
          if (code == 200) {
            this.step = 1;
            this.satv = data.sa_tv;
            if (data.accessToken) {
              this.headAccesToken = data.accessToken;
            }
            if (data.sa_tv === "PM_ME") {
              this.$router.replace("/login");
            }
          }
        }
      });
    },

    onTimeConfirm(option) {
      this.ruleForm.birthdayDay = this.$d(option).format("YYYY-MM-DD");
      this.showBirthday = false;
    },

    onCommunistTime(option) {
      this.ruleForm.communistTime = this.$d(option).format("YYYY-MM-DD");
      this.showCommunistTime = false;
    },

    onEntryDate(option) {
      this.ruleForm.entryDate = this.$d(option).format("YYYY-MM-DD");
      this.showEntryDate = false;
    },
    validtorEmail(val) {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (val) {
        return reg.test(val);
      }
    },
    validtorWorkYear(val) {
      return /^\d{1,3}(\.5)?$/.test(val);
    },
    // 提交入职信息
    validKeep() {
      this.$refs.FormStaffInfo.validate([
        "userName",
        "gender",
        "idCard",
        "birthdayDay",
        "phoneNumber",
        "emailAddress",
        "educationBack",
        "collegeName",
        "entryDate",
        "workYear",
        "staffDuty",
        "emergencyPhone",
      ]).then(() => {
        let eduAcademics = [];
        eduAcademics.push({
          academic: this.ruleForm.educationBack,
          collegeName: this.ruleForm.collegeName,
          faculty: this.ruleForm.faculty,
          major: this.ruleForm.major,
          type: 0,
        });
        const params = {
          ...this.ruleForm,
          accessToken: this.headAccesToken,
          eduAcademics: eduAcademics,
        };
        saveStaffInfo(params).then((res) => {
          if (res) {
            let { code, msg, success } = res;
            if (code == 200) {
              this.$router.replace("/login");
            }
            this.$message({
              message: msg,
              type: success ? "success" : "error",
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.intive-to-join-page {
  width: 100%;
  background: #edeff4;
  height: 100%;
  overflow: hidden;
  .intive-to-join {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .intive-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
    background: #fff;
    .title-box {
      background: #f0f6ff;

      .title {
        text-align: center;
      }
    }
    .form-staff-info {
      flex: 1;
    }
  }
  .write {
    background: #edeff4;
    .van-cell {
      line-height: 24px !important;
    }
  }
  .footer-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 0.3rem;
    width: 7.5rem;
    height: 1.04rem;
    background: #ffffff;
    box-shadow: 0 0.06rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    align-items: center;
    .pass {
      width: 6rem;
      height: 0.8rem;
      background: #0094ee;
      border-radius: 0.4rem;
      font-size: 0.26rem;
      color: #ffffff;
      line-height: 0.37rem;
    }
  }
  .card-box {
    // background: #fff;
    // padding: 0.2rem 0.1rem;
    margin-bottom: 0.2rem;
    .form-title {
      font-size: 0.3rem;
      font-weight: 700;
      padding: 0.2rem 0.1rem;
    }
  }
  .van-cell {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.2rem;
  }
  .cell {
    .title {
      font-size: 0.3rem;
      font-weight: 600;

      font-size: 0.3rem;
    }
  }
  .red {
    color: red;
  }
}
</style>
